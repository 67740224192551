import React, { useState, useEffect, lazy, Suspense } from "react";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Routes } from "./routes";
import Preloader from "./Preloader";
import dbData from "./dbData";

function RouteLayout() {
  const query = new URLSearchParams(useLocation().search);
  const decodedParamName = decodeURIComponent("FM%2Ak%249a7r");
  let uri = dbData[query.get(decodedParamName)]
    ? query.get(decodedParamName)
    : localStorage.getItem("uri");
  function setCompanyData(currentURI, dbName) {
    localStorage.setItem("uri", currentURI);
    localStorage.setItem("tableName", dbName);
  }

  useEffect(() => {
    if (uri) {
      if (dbData[uri] !== undefined) {
        setCompanyData(uri, dbData[uri]["db"]);
        async function connectDB() {
          try {
            await fetch("https://api.isvmsavm.com/api/database", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ database: dbData[uri]["db"] }),
            });
          } catch (error) {
            console.error("Error:", error);
          }
          // try {
          //   await fetch("http://localhost:5000/api/database", {
          //     method: "POST",
          //     headers: { "Content-Type": "application/json" },
          //     body: JSON.stringify({ database: dbData[uri]["db"] }),
          //   });
          // } catch (error) {
          //   console.error("Error:", error);
          // }
        }
        connectDB();
      }
    }
  }, [uri]);

  const currentUrl = window.location.href;
  const cleanUrl = currentUrl.split("?")[0];
  window.history.replaceState({}, document.title, cleanUrl);

  const RouteWithLoader = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);

    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            {" "}
            <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
          </>
        )}
      />
    );
  };

  const RouteWithSidebar = ({ component: Component, ...rest }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => setLoaded(true), 1000);
      return () => clearTimeout(timer);
    }, []);

    const localStorageIsSettingsVisible = () => {
      return localStorage.getItem("settingsVisible") === "false" ? false : true;
    };

    const [showSettings, setShowSettings] = useState(
      localStorageIsSettingsVisible
    );

    const toggleSettings = () => {
      setShowSettings(!showSettings);
      localStorage.setItem("settingsVisible", !showSettings);
    };

    return (
      <Route
        {...rest}
        render={(props) => (
          <>
            <Preloader show={loaded ? false : true} />
            {uri !== null ? (
              <>
                <Sidebar />
                <main className="content">
                  <Navbar />
                  <Component {...props} />
                  <Footer
                    toggleSettings={toggleSettings}
                    showSettings={showSettings}
                  />
                </main>
              </>
            ) : null}
          </>
        )}
      />
    );
  };

  // pages
  const Presentation = lazy(() => import(`./${uri}/pages/examples/Signin`));
  const DashboardOverview = lazy(() =>
    import(`./${uri}/dashboard/DashboardOverview`)
  );
  const Transactions = lazy(() => import(`./${uri}/pages/Transactions`));
  const Expenses = lazy(() => import(`./${uri}/pages/expenses/page`));
  const Purchase = lazy(() => import(`./${uri}/Purchase/Purchase`));
  const Sales = lazy(() => import(`./${uri}/Sales/Sales`));
  const Settings = lazy(() => import(`./${uri}/pages/Settings`));
  const BootstrapTables = lazy(() =>
    import(`./${uri}/pages/tables/BootstrapTables`)
  );
  const Signin = lazy(() => import(`./${uri}/pages/examples/Signin`));
  const Signup = lazy(() => import(`./${uri}/pages/examples/Signup`));
  const ResetPassword = lazy(() =>
    import(`./${uri}/pages/examples/ResetPassword`)
  );
  const Valuetargets = lazy(() =>
    import(`./${uri}/pages/examples/Valuetargets`)
  );
  const Accountingsettings = lazy(() =>
    import(`./${uri}/pages/examples/Accountingsettings`)
  );
  const Supplierssettings = lazy(() =>
    import(`./${uri}/pages/examples/Supplierssettings`)
  );
  const BeginningInventory = lazy(() =>
    import(`./${uri}/Beginning/BeginningInventorysettings`)
  );
  const Bomsettings = lazy(() => import(`./${uri}/pages/examples/Bomsettings`));
  const ChangeProfile = lazy(() => import(`./${uri}/pages/changeprofile`));
  const Inventory = lazy(() => import(`./${uri}/Inventory/Inventory`));
  const IncomeStatement = lazy(() =>
    import(`./${uri}/IncomeStatement/IncomeStatement`)
  );
  const CloseAccount = lazy(() => import(`./${uri}/pages/closeAccount/page`));
  const Sidebar = lazy(() => import(`./${uri}/components/Sidebar`));
  const Navbar = lazy(() => import(`./${uri}/components/Navbar`));
  const Footer = lazy(() => import(`./${uri}/components/Footer`));

  return (
    <Suspense fallback={<Preloader />}>
      <Switch>
        <RouteWithLoader
          exact
          path={Routes.Presentation.path}
          component={Presentation}
        />
        <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
        <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
        <RouteWithLoader
          exact
          path={Routes.ResetPassword.path}
          component={ResetPassword}
        />

        {/* pages */}
        <RouteWithSidebar
          exact
          path={Routes.DashboardOverview.path}
          component={DashboardOverview}
        />
        <RouteWithSidebar
          exact
          path={Routes.ChangeProfile.path}
          component={ChangeProfile}
        />
        <RouteWithSidebar
          exact
          path={Routes.Purchase.path}
          component={Purchase}
        />
        <RouteWithSidebar exact path={Routes.Sales.path} component={Sales} />
        <RouteWithSidebar
          exact
          path={Routes.Transactions.path}
          component={Transactions}
        />
        <RouteWithSidebar
          exact
          path={Routes.Expenses.path}
          component={Expenses}
        />
        <RouteWithSidebar
          exact
          path={Routes.CloseAccount.path}
          component={CloseAccount}
        />
        <RouteWithSidebar
          exact
          path={Routes.Settings.path}
          component={Settings}
        />
        <RouteWithSidebar
          exact
          path={Routes.BootstrapTables.path}
          component={BootstrapTables}
        />
        <RouteWithSidebar
          exact
          path={Routes.Valuetargets.path}
          component={Valuetargets}
        />
        <RouteWithSidebar
          exact
          path={Routes.Accountingsettings.path}
          component={Accountingsettings}
        />
        <RouteWithSidebar
          exact
          path={Routes.Supplierssettings.path}
          component={Supplierssettings}
        />
        <RouteWithSidebar
          exact
          path={Routes.BeginningInventorysettings.path}
          component={BeginningInventory}
        />
        <RouteWithSidebar
          exact
          path={Routes.Bomsettings.path}
          component={Bomsettings}
        />
        <RouteWithSidebar
          exact
          path={Routes.Inventory.path}
          component={Inventory}
        />
        <RouteWithSidebar
          exact
          path={Routes.IncomeStatement.path}
          component={IncomeStatement}
        />
        <Redirect to={Routes.NotFound.path} />
      </Switch>
    </Suspense>
  );
}

export default RouteLayout;
